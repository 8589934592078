import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import '../src/styles/cel_sec1.css'
import OffreSelect from './OffreSelect';

const CEL_sec1 = ({ udapdeChildBasket }) => {

    const [price, setPrice] = useState('')
    const [pricePromo, setPricePromo] = useState('')
    const [loading, setLoading] = useState(false)
    const unit = ["mois", "mois", "Avec suivi 6/7 jours", "Sans suivi"]

    const updatePrice = (arrayPrice) => {
        if (arrayPrice != undefined) {
            setPrice(arrayPrice[0])
            if (arrayPrice.length === 2)
                setPricePromo(arrayPrice[1])
            else
                setPricePromo(null)
        }
    }

    const responseAPIStatusOk = (status) => {
        setLoading(status)
    }

    return (
        <div className='container'>
            <Row>
                <h1 className='fw-bold' id="title" >Coaching en Ligne</h1>
                <h3 className='fw-bold text-center priceText'>{loading ? (
                    <section >
                        {pricePromo != null ? (
                            <section>
                                <span id='pricePromo'>{pricePromo}€  </span>
                                <span id='oldPrice'>{price}€</span>
                            </section>
                        ) : (
                            <span className=''>{price}€</span>
                        )}
                    </section>
                ) : (null)} </h3>
            </Row>
            <Row id="tarifTitle">
                <Col id="text" sm={4}>
                    <Row>
                        <OffreSelect productId={1} updatePrice={updatePrice} udapdeChildBasket={udapdeChildBasket} responseAPIStatusOk={responseAPIStatusOk} unit={unit} />
                    </Row>
                </Col>
                <Col>
                    <h1 className='fw-bold text-center' id="tarifLegend">Comment le coaching en ligne se déroule-t-il ?</h1>
                    <h4 className='text-center'>Une fois le paiement effectué, le coaching se déroulera étape par étape afin de réaliser le meilleur suivi personnalisé possible.</h4>
                </Col>
            </Row>
            <Row id="textSousTarif">
                <Col>
                    <p className='justifyText'>
                        Une fiche de renseignements vous sera envoyée par mail afin de pouvoir mieux vous connaître et ainsi cerner au mieux vos attentes et vos objectifs. Une fois la réception de vos réponses, je vous transmettrai une première séance (le bilan de forme), qui me permettra de connaître vos capacités cardio-vasculaire, musculaires et aussi votre  souplesse & équilibre.<br /><br />
                        Par la suite je commencerai la création de votre programme personnalisé que vous recevrez par mail dans un délai de 3 jours. Votre programme sera réalisé en fonction de votre/vos objectif(s), de vos attentes, de vos blessures,  de vos antécédents et votre quotidien etc.<br /><br />
                        Chaque fin de semaine, nous effectuerons un bilan afin que je puisse répondre à vos questions et que l'on discute de vos ressentis post séances de la semaine.
                    </p>
                </Col>
            </Row>

        </div>
    );
};

export default CEL_sec1;