import React from 'react';
import { CDBFooter, CDBBtn, CDBIcon, CDBBox } from 'cdbreact';
import '../src/styles/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { Navigate, useNavigate } from 'react-router-dom';

const Footer = () => {

  const goInsta = () => {
    window.location.replace('https://instagram.com/coaching_val?igshid=YmMyMTA2M2Y=');
  }

  const goFB = () => {
    window.location.replace('https://instagram.com/coaching_val?igshid=YmMyMTA2M2Y=');
  }

  const navigate = useNavigate();

  const mentionsLegales = event => {
    console.log("mentions légales")
    navigate('/mentionsLegales', { replace: true })
  }

  return (
    <CDBFooter className="shadow" id="footer">
      <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{ width: '90%' }}>
        {/*<CDBBox display="flex" justifyContent="between" className="flex-wrap">*/}
        <Row xs={12} sm={3}>
          <Col xs={12} sm={3}>
            <a href="/" className="d-flex align-items-center p-0 text-dark" id="urlNameWebSite">
              <img alt="logo" src="src/pictures/logo.svg" width="60px" className='logo' />
              <span className="ml-3 h5 font-weight-bold" id="marginBetweenNameWebSiteAndLogo" >Coaching-Val</span>
            </a>
            <p className="my-3" style={{ width: '250px' }}>
              <ul>
                <li><h5>Coaching Musculation</h5></li>
                <li><h5>Haltérophilie</h5></li>
                <li><h5>Crossfit</h5></li>
              </ul>
            </p>
          </Col>
          <Col xs={12} sm={9}>
            <Row id="rightFooterInfos" className='mt-4' xs={1} sm={3}>
              <Col>
                <h5 className='textFooter' style={{ fontWeight: '600' }}>
                  <FontAwesomeIcon className='text-danger' icon={faLocationDot} /> Les Sables d'Olonne - La Roche sur Yon
                </h5>
              </Col>
              <Col>
                <h5 className='textFooter' style={{ fontWeight: '600' }}>
                  <FontAwesomeIcon className='text-danger' icon={faClock} /> Lundi - Samedi -- 07:00 - 21:30
                </h5>
              </Col>
              <Col>
                <h5 className='textFooter' style={{ fontWeight: '600' }}>
                  <FontAwesomeIcon className='text-danger' icon={faPhone} /> 06.28.75.02.06
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
        {/*</CDBBox>*/}
        <CDBBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%' }}
          className="mx-auto mt-4"
        >
          <Row>
            <small className="text-center">
              &copy; Coaching-Val, 2022. All rights reserved, webMaster : <span className="fst-italic fw-bold">Kévin MAITRE</span>.
            </small>
            <small className='text-center'>
              <a id="mentionsLegales" onClick={mentionsLegales}>Mentions légales</a>
            </small>
          </Row>
        </CDBBox>
        <Row id="logoBarFooter">
          {/*
          <CDBBtn flat color="dark" className="mx-3 p-2" onClick={goFB}>
            <CDBIcon fab icon="facebook-f" />
          </CDBBtn>
        */}
          <CDBBtn flat color="dark" className="p-1 logoCenterFooter" id="instaBTN" onClick={goInsta}>
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
        </Row>
      </CDBBox>
    </CDBFooter >
  );
};

export default Footer;