import React from 'react';
import '../src/styles/HomeBrique1_imageTop.css'

const HomeBrique1_imageTop = () => {
    return (
        <div>
            <img alt="homeBg.jpeg" src='src/pictures/homeBg.jpeg' id='imgHome' />
            <h1 id="texteOverBg">Ta transformation est la prochaine !</h1>
        </div>
    );
};

export default HomeBrique1_imageTop;