import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import API from '../../components/Api'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { decode } from './../EncodeDecrypt';
import CardItemBasket from '../CardItemBasket';
import validator from 'validator';
import AppleGooglePay from './AppleGooglePay';

const CheckoutForm = ({ resetBasketNavCheckoutForm }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [data, setData] = useState('')
    const navigate = useNavigate();
    const [errorBasket, setErrorBasket] = useState(false)
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [name, setName] = useState('')

    const handleEmptyBasket = event => {
        localStorage.removeItem('basket')
        navigate('/', { replace: true })
        resetBasketNavCheckoutForm()
    }

    useEffect(() => {
        let data = ""
        try {
            data = decode(localStorage.getItem("basket"))
            console.log(data)
            setData(data)
        } catch (e) {
            setErrorBasket(true)
            console.log(e)
        }
    }, [])


    const handleSubmit = async (event) => {
        event.preventDefault()
        console.log(email + " " + mobileNumber + " " + name)
        if (validator.isEmail(email) && mobileNumber.length == 14 && name != "") {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
            })
            if (!error) {
                console.log("Token Généré: ", paymentMethod)
                // send to back the token
                try {
                    const { id } = paymentMethod
                    const response = await API.post(
                        '/payement',
                        {
                            id: id,
                            uuid: data.id,
                            email: email,
                            phoneNumber: mobileNumber,
                            name: name
                        });

                    if (response.status === 200 && response.data === "ok") {
                        toast.success("Achat confirmé, merci :)", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        handleEmptyBasket()
                        console.log("paiement réusi")
                    } else {
                        console.log(response.data.split(";")[0])
                        toast.error(response.data.split(":")[1].split(";")[0] + " :'(", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                }
                catch (error) {
                    toast.error("Un problème est survenu :(", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error)
                }
            } else {
                toast.error("Un problème est survenu :(", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        } else {
            toast.error("Vérifiez vos informations", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    const OPTIONS = {
        showIcon: true,
        placeholder: "Numéro de carte"
    }

    const handleChangeUserName = (e) => {
        if (e.target.value.match("^[a-zA-Z\u00C0-\u00FF-]*$") != null) {
            setName(e.target.value);
        }
    }

    const handleChangePhoneNumber = (e) => {
        let val = e.target.value.split(' ').join('');      //remove all dashes (-)
        if (val.match("^[0-9]*$") != null && val.length < 11) {
            try {
                let finalVal = val.match(/.{1,2}/g).join(' ');//add dash (-) after every 3rd char.
                setMobileNumber(finalVal);
            } catch (err) {
                setMobileNumber("");
            }
        }
    }

    const handleChangeMail = (e) => {
        if (e.target.value.match("^[a-zA-Z-_.@0-9]*$") != null) {
            setEmail(e.target.value);
        }
    }

    return (
        <div className='container'>
            {!errorBasket ? (
                <Row>
                    <Col xs={12} sm={6} >
                        <Row>
                            <h1>Votre panier :</h1>
                        </Row>
                        <Row id="rowRecap">
                            <section className="cadreBasket" id="cadreBasket">
                                {!data ? null : (
                                    <CardItemBasket data={data} />
                                )}
                            </section>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <h3>Total </h3>
                            </Col>
                            <Col>
                                <h5 className='fw-bold'>
                                    {data.promo ? (
                                        <section>
                                            <span className='pricePromoBasket pricePromoBasketTotal'>{data.pricePromo}€  </span>
                                            <span className='oldPriceBasket oldPriceBasketTotal'>{data.price}€</span>
                                        </section>
                                    ) : (
                                        <span className=''>{data.price}€</span>
                                    )}
                                </h5>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={6} id="formPaid">
                        <div id="sepaBasket" />
                        <form onSubmit={handleSubmit}>
                            <Container className="">
                                <span><p id="titleCB">Informations :</p></span>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" value={name} placeholder="Entrez votre nom prénom..." onChange={handleChangeUserName} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="tel" value={mobileNumber} maxlength="14" placeholder="Entrez votre téléphone..." onChange={handleChangePhoneNumber} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="mail" value={email} placeholder="Entrez votre mail..." onChange={handleChangeMail} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                            <span><p id="titleCB">Carte bancaire :</p></span>
                            <Container className="cadreBasket">
                                <Row className='cardForm' id="cardNumberElet">
                                    <Col>
                                        <CardNumberElement id="cardNumberElt" options={OPTIONS} />
                                    </Col>
                                </Row>
                                <Row id="bottomCard">
                                    <Col className='cardForm' id="sepaPaidForm">
                                        <CardExpiryElement id="CardExpiryElement" />
                                    </Col>
                                    <Col className='cardForm'>
                                        <CardCvcElement id="CardCvcElement" />
                                    </Col>
                                </Row>
                            </Container>

                            <Button className='buttonsBasket' id="paidButon" type="submit">Payer {data.promo ? (data.pricePromo) : (data.price)} €</Button>

                        </form >
                        {!data ? null : (
                            <AppleGooglePay data={data} razBasket={handleEmptyBasket} />
                        )}
                        <Button className='buttonsBasket' id="emptyBsket" onClick={handleEmptyBasket} variant="warning">Videz le panier...</Button>
                    </Col>
                </Row >
            ) : (null)}
        </div >
    );
};

export default CheckoutForm;