import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Card_cev = () => {
    return (
        <div className='container'>
            <Row xs={1} sm={4}>
                <Col className='d-flex'>
                    <Card id="cadreOneCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title'>Étape n°1</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle'>
                                    Contact / Appel
                                </h4>
                            </Card.Subtitle>
                            <Card.Title>
                                <ul>
                                    <li><h5>Fiche de renseignements</h5></li>
                                    <li><h5>Élaboration de votre objectif</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreTwoCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title text-white'>Étape n°2</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle text-white'>
                                    Création de séances personnalisées :
                                </h4>
                            </Card.Subtitle>
                            <Card.Title className='text-white'>
                                <ul>
                                    <li><h5>A votre objectif défini</h5></li>
                                    <li><h5>A vos antécédents médicaux et sportifs</h5></li>
                                    <li><h5>A votre quotidien</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreThreeCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title text-white'>Étape n°3</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle text-white'>
                                    Jour de Séance
                                </h4>
                            </Card.Subtitle>
                            <Card.Title className='text-white'>
                                <ul>
                                    <li><h5>Connection Visio via WhatsApp</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreFourCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title'>Étape n°4</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle'>
                                    Bilan / Suivi
                                </h4>
                            </Card.Subtitle>
                            <Card.Title>

                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Card_cev;