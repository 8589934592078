import React, { useEffect, useState } from 'react';
import API from '../components/Api'
import Pagination from './Pagination';
import Review from './Review';


const PaginateComponent = () => {
    const [review, setReview] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [reviewPerPage, setReviewPerPage] = useState(5)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        API
            .get('/feedback')
            .then((res) => [
                setReview(res.data),
                setLoading(false)
            ]);
    }, []);

    const indexOfLastReview = currentPage * reviewPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewPerPage;
    const currentReview = review.slice(indexOfFirstReview, indexOfLastReview);

    //change page
    const paginate = pageNumber => { setCurrentPage(pageNumber) }

    const paginateNext = (currentPage, nbMaxPage) => {
        let nb
        if (currentPage < nbMaxPage) {
            nb = currentPage + 1

        } else {
            nb = nbMaxPage
        }
        setCurrentPage(nb)
    }
    const paginatePrev = (currentPage) => {
        let nb
        if (1 < currentPage) {
            nb = currentPage - 1

        } else {
            nb = 1
        }
        setCurrentPage(nb)
    }

    const firstLastPage = (order, nbMaxPage) => {
        if (order) {
            setCurrentPage(1)
        } else {
            setCurrentPage(nbMaxPage)
        }

    }

    return (
        <div>
            <Review reviews={currentReview} loading={loading} />
            <Pagination
                currentPage={currentPage}
                reviewPerPage={reviewPerPage}
                totalReviews={review.length}
                paginate={paginate}
                paginateNext={paginateNext}
                paginatePrev={paginatePrev}
                firstLastPage={firstLastPage}
            />
        </div>
    );
};

export default PaginateComponent;