import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../src/styles/HomeBrique2_VotreCoach.css'

const HomeBrique2_VotreCoach = () => {
    return (
        <div className='sepaTop'>
            <Row className='homeRow'>
                <Col id="text" sm={8}>
                    <section id='sectionCenterText'>
                        <Row className='homeRow'>
                            <Col><h1 className='fw-bold'>Votre coach</h1></Col>
                        </Row>
                        <Row className='homeRow'>
                            <Col><h2 className='fw-bold'>Valentin FAVRELIERE</h2></Col>
                        </Row>
                        <Row className='homeRow'>
                            <Col id="bio">
                                <p id="textBio">
                                    Diplômé Educateur Multisports BP JEPS APT, Animateur Gymnique et Coach Sportif BP JEPS AF mention musculation & haltérophilie.<br />
                                    Fort d'une expérience personnelle de plus de 10 ans dans la musculation, je vous apporte mon soutien et mes connaissances pour que chaque coaching vous rapproche un peu plus de votre objectif.<br />
                                    Sportif depuis ma jeunesse, je me suis spécialisé dans le coaching en club de remise en forme, à domicile et à distance.<br />
                                    Plus qu'un métier, c'est devenu pour moi une passion. C'est ce goût du sport que je cherche à vous transmettre pour vous guider et vous faire progresser afin de vous faire atteindre votre objectif.<br />
                                </p>
                            </Col>
                        </Row>
                    </section>
                </Col>
                <Col className='d-flex flex-wrap align-items-center' sm={4}>
                    <section id="cadre" className='d-flex flex-wrap align-items-center'>
                        <img alt="picVotreCoach.jpeg" src='src/pictures/picVotreCoach.jpeg' id="picVal" className='align-middle' />
                    </section>
                </Col>
            </Row >
        </div >
    );
};

export default HomeBrique2_VotreCoach;