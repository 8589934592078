import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import '../src/styles/cad.css'
import CardDetailsCad from '../components/CardDetailsCad';
import OffreSelect from '../components/OffreSelect';

const Cad = ({ udapdeChildBasket }) => {
    const [price, setPrice] = useState('')
    const [pricePromo, setPricePromo] = useState('')
    const [loading, setLoading] = useState(false)
    const unit = ["séance", "séances", "Solo", "Duo"]

    const updatePrice = (arrayPrice) => {
        if (arrayPrice != undefined) {
            setPrice(arrayPrice[0])
            if (arrayPrice.length === 2)
                setPricePromo(arrayPrice[1])
            else
                setPricePromo(null)
        }
    }

    const responseAPIStatusOk = (status) => {
        setLoading(status)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {/*<Navigation ref={myRef} />*/}
            <div id='offsetMenu' />
            <div className='container'>
                <Row>
                    <h1 className='fw-bold' id="title" >Coaching à domicile</h1>
                    <h3 className='fw-bold text-center priceText'>{loading ? (
                        <section >
                            {pricePromo != null ? (
                                <section>
                                    <span id='pricePromo'>{pricePromo}€  </span>
                                    <span id='oldPrice'>{price}€</span>
                                </section>
                            ) : (
                                <span className=''>{price}€</span>
                            )}
                        </section>
                    ) : (null)} </h3>
                </Row>
                <Row id="tarifTitle">
                    <Col id="text" sm={4}>
                        <Row>
                            <OffreSelect productId={3} updatePrice={updatePrice} udapdeChildBasket={udapdeChildBasket} responseAPIStatusOk={responseAPIStatusOk} unit={unit} />
                        </Row>
                    </Col>
                    <Col>
                        <h1 className='fw-bold text-center' id="tarifLegend">Pourquoi faire appel à un coach sportif ?</h1>
                    </Col>
                </Row>
            </div>
            <CardDetailsCad />
            <Footer />
        </div>
    );
};

export default Cad;