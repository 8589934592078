import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const StripeContainer = ({ resetBasketNavStripeContainer }) => {

    const resetBasketNavCheckoutForm = () => {
        console.log("reset resetBasketNavCheckoutForm")
        resetBasketNavStripeContainer()
    }

    const stripeTestPromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY)
    return (
        <Elements stripe={stripeTestPromise} >
            <CheckoutForm resetBasketNavCheckoutForm={resetBasketNavCheckoutForm} />
        </Elements>
    );
};

export default StripeContainer;