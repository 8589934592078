import React from 'react';
import '../src/styles/HomeBrique3_VotreCoach.css'

const HomeBrique3_Votreobjectif = () => {
    return (
        <div>
            <div id="container">
                <section id="sepaRed" className='text-center' />
                <h3 className='text-white text-center paddingText'>
                    Votre objectif deviendra le mien !
                </h3>
                <h4 className='text-white text-center paddingText'>
                    EN LIGNE / DOMICILE / VISIO / SALLE / EXTÉRIEUR
                </h4>
                <h6 className='text-white text-center paddingText'>
                    Tu souhaites perdre du poids, te défouler, te renforcer pour le quotidien ou bien prendre du muscle ?
                </h6>
                <h4 className='text-danger text-center paddingText'>
                    Ta transformation est la prochaine !
                </h4>
                <p
                    className='text-white text-center paddingText'>Le coaching est réalisé sur mesure en fonction de ton objectif et de ton expérience.<br />
                    Reste motivé et obtiens enfin des réultats avec l'aide d'un professionnel.
                </p>
            </div>
        </div>
    );
};

export default HomeBrique3_Votreobjectif;