import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import Card_cev from '../components/Card_cev';
import OffreSelect from '../components/OffreSelect';

const Cev = ({ udapdeChildBasket }) => {
    const [price, setPrice] = useState('')
    const [pricePromo, setPricePromo] = useState('')
    const [loading, setLoading] = useState(false)
    const unit = ["séance", "séances", "Solo", "Duo"]

    const updatePrice = (arrayPrice) => {
        if (arrayPrice != undefined) {
            setPrice(arrayPrice[0])
            if (arrayPrice.length === 2)
                setPricePromo(arrayPrice[1])
            else
                setPricePromo(null)
        }
    }

    const responseAPIStatusOk = (status) => {
        setLoading(status)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div id='offsetMenu' />
            <div className='container'>
                <Row>
                    <h1 className='fw-bold' id="title" >Coaching en Visio</h1>
                    <h3 className='fw-bold text-center priceText'>{loading ? (
                        <section >
                            {pricePromo != null ? (
                                <section>
                                    <span id='pricePromo'>{pricePromo}€  </span>
                                    <span id='oldPrice'>{price}€</span>
                                </section>
                            ) : (
                                <span className=''>{price}€</span>
                            )}
                        </section>
                    ) : (null)} </h3>
                </Row>
                <Row id="tarifTitle">
                    <Col id="text" sm={4}>
                        <Row>
                            <OffreSelect productId={2} updatePrice={updatePrice} udapdeChildBasket={udapdeChildBasket} responseAPIStatusOk={responseAPIStatusOk} unit={unit} />
                        </Row>
                    </Col>
                    <Col>
                        <h1 className='fw-bold text-center' id="tarifLegend">Comment le coaching en Visio se déroule-t-il ?</h1>
                        <h4 className=' text-center'>Une fois le paiement effectué, le coaching se déroulera étape par étape afin de réaliser la meilleure séance personnalisée possible.</h4>
                    </Col>
                </Row>
            </div>
            <Card_cev />
            <Footer />
        </div>
    );
};

export default Cev;