import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import LeaveAReview from '../components/LeaveAReview';
import PaginateComponent from '../components/PaginateComponent';
import '../src/styles/pagination.css'
import Navigation from './../components/Navigation';

const Avis = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //"build": "react-scripts build && mkdir -p ./build/.well-known && cp ./src/applePay/apple-developer-merchantid-domain-association ./build/.well-known/",

    return (
        <div>
            {/*<Navigation />*/}
            <section className='container'>
                <div id='offsetMenu' />
                <h1 className='text-center'>Avis</h1>
                <PaginateComponent />
                <LeaveAReview />
            </section>
            <Footer />
        </div >
    );
};

export default Avis;