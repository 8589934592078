import { AES, enc } from 'crypto-js';

const hashEncodeDecode = process.env.REACT_APP_HASH_LOCAL_STORAGE

function encode(sentenceToEncrypt) {
    return AES.encrypt(sentenceToEncrypt, hashEncodeDecode).toString()
}

function decode(sentenceToDecrypt) {
    try {
        return JSON.parse(AES.decrypt(sentenceToDecrypt, hashEncodeDecode).toString(enc.Utf8))
    } catch (err) {
        localStorage.removeItem("basket")
    }
}

export {
    encode,
    decode
};