import React, { useEffect, useRef } from 'react';
import Card_ces from '../components/Card_ces';
import CES_sec1 from '../components/CES_sec1';
import Footer from '../components/Footer';

const Ces = ({ udapdeChildBasket }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const udapdeBasket = () => {
        udapdeChildBasket()
    }

    return (
        <div>
            <div id='offsetMenu' />
            <CES_sec1 udapdeChildBasket={udapdeBasket} />
            <Card_ces />
            <Footer />
        </div>
    );
};

export default Ces;