import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import '../src/styles/contact.css'
import API from '../components/Api'
import { toast } from 'react-toastify';
import Navigation from './../components/Navigation';
import validator from 'validator';

const Contact = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSendFormContact = async (e) => {
        let error = ""
        if (validator.isEmail(email)) {
            if (mobileNumber.length == 10) {
                e.preventDefault();
                try {
                    const response = await API.post("/contact",
                        {
                            name: name,
                            email: email,
                            mobileNumber: mobileNumber,
                            message: message
                        })
                    console.log(response)
                    if (response.status === 200 && response.data === "ok") {
                        toast.success("Mesage envoyé :)", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log("envoyé...")
                    } else {
                        toast.error("Mesage non envoyé :(", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log("Non envoyé..." + response.data)
                    }
                } catch (err) {
                    toast.error("Mesage non envoyé :(", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(err)
                }
            } else {
                error = "Numéro de téléphone invalide"
            }
        } else {
            error = "Mail invalide"

        }
        if (error != "") {
            toast.error(error, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    const handleChangeUserName = (e) => {
        if (e.target.value.match("^[a-zA-Z ]*$") != null) {
            setName(e.target.value);
        }
    }

    const handleChangePhoneNumber = (e) => {
        if (e.target.value.match("^[0-9]*$") != null) {
            setMobileNumber(e.target.value);
        }
    }

    return (
        <div>
            {/*<Navigation />*/}
            <div id='offsetMenu' />
            <section className='container'>
                <h1 id="contactMeTitle">Contactez-moi</h1>
                <Form id="formContact">
                    <Row>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Control type="email" value={name} placeholder="Entrez votre nom..." onChange={handleChangeUserName/*(e) => setName(e.target.value)*/} />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Control type="email" value={email} placeholder="Entrez votre mail..." onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3">
                                <Form.Control type="tel" pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' value={mobileNumber} placeholder="Entrez votre téléphone..." onChange={handleChangePhoneNumber/*(e) => setMobileNumber(e.target.value)*/} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="mb-3">
                                <Form.Control as="textarea" value={message} rows={10} id='textMessage' placeholder="Entrez votre mesage..." onChange={(e) => setMessage(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='rowEmptyMargin' >
                        <Button variant="danger" id="sendFormContact" onClick={handleSendFormContact}>Envoyer</Button>
                    </Row>
                </Form>
            </section>
            <Footer />
        </div>
    );
};

export default Contact;