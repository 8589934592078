import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import '../src/styles/navigation.css'
import { Link as link1, Navigate, useLocation, useNavigate } from "react-router-dom"
import * as link2 from "react-scroll"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import { decode } from './../components/EncodeDecrypt';
import { toast } from 'react-toastify';
import { Fade as Hamburger } from 'hamburger-react'

const Navigation = React.forwardRef(({ }, ref) => {

    const navigateRefreshBasket = () => {
        // update childDataApi and pass it to parent
        const res = decode(localStorage.getItem("basket"))
        setNbArticle(1)
        console.log("navigation refresh basket")
    }

    const resetBasketApp = () => {
        setNbArticle(0)
        console.log("reset to 0 Navigation")
    }

    const resetBasketAfterTouchLocalStorage = () => {
        setNbArticle(0)
    }

    useImperativeHandle(ref, () => ({
        navigateRefreshBasket,
        resetBasketApp,
        resetBasketAfterTouchLocalStorage
    }));

    const path = useLocation().pathname;
    const location = path.split('/')[1];
    const history = useNavigate();
    const scroller = link2.scroller;

    const [expanded, setExpanded] = useState('');
    const [stateMenu, setStateMenu] = useState(false);
    const [nbArticle, setNbArticle] = useState(0);
    const [isOpen, setOpen] = useState(false)
    const [windowSize, setWindowSize] = useState(getWindowSize())
    const [dropDown, setDropDown] = useState(true)

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const scrollToTopMainPage = () => {
        scroller.scrollTo('topPage', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -25
        });
    };

    const scrollToAnchor = () => {
        scroller.scrollTo('anchor', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -25
        });
    };

    const goToHomeAndScroll = async () => {
        await history('/')
        scrollToAnchor()
        //window.scrollTo(0, 525)
    };

    const askMenuBurger = () => {
        //false close and true open
        if (windowSize.innerWidth <= 990) {
            if (stateMenu) {
                //close
                setStateMenu(false)
                setExpanded('')
            } else {
                //open
                setStateMenu(true)
                setExpanded("1")

            }
        }
    }

    useEffect(() => {
        setDropDown(false)
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        console.log(windowSize)
        if (localStorage.getItem("basket") != null) {
            let ref = []
            try {
                ref = decode(localStorage.getItem("basket"))
                console.log(ref)
                if (ref != undefined) {
                    //const res = decode(localStorage.getItem("basket"))
                    setNbArticle(1)
                } else
                    setNbArticle(0)
            } catch (e) {
                /*
                setNbArticle(0)
                toast.error("Ne touche pas au local storage coquin :@", {
                    position: toast.POSITION.BOTTOM_CENTER
                });*/
                resetBasketAfterTouchLocalStorage()
                console.log(e)
            }
        } else {
            console.log("no article")
            setNbArticle(0)
        }
        console.log("nb article " + nbArticle)
    }, [])

    function dropDownFunction() {
        setDropDown(!dropDown)
    }

    return (
        <Navbar id="topMenu" fixed="top" bg="light" expand="lg" expanded={expanded}>
            <Navbar.Brand id="imageZone" as={link1} to={"/"}>
                <section id="logoMenu">
                    <img
                        alt="Coaching-val"
                        src="src/pictures/logo.svg"
                        className="d-inline-block align-top marginImg logo"
                        id="imgLogo"
                    />
                </section>
                <section id="menuText">
                    <span className='topBarText' id="textNameCoachSportif">Valentin FAVRELIERE</span>
                    <span className='topBarText' id="textCoachSportif">Coach Sportif</span>
                </section>
            </Navbar.Brand>
            {/*
            {nbArticle >= 1 ? (
                <Navbar.Brand id="panier" as={link1} to={"/panier"}>
                    <button className='navLink' id="buttonPanier">
                        <FontAwesomeIcon className='text-danger' id="logo" icon={faBasketShopping} />
                        <svg id="numberArticleSVG" height="25" width="25">
                            <circle cx="12.5" cy="12.5" r="10" stroke="black" stroke-width="2" fill="white" />
                            <text id="textNumberArticleSVG" x="8" y="17">{nbArticle}</text>
                        </svg>
                    </button>
                </Navbar.Brand>
            ) : (
                < Navbar.Brand id="panier">
                    <button className='navLink buttonBasketOff' id="buttonPanier">
                        <FontAwesomeIcon className='text-secondary' id="logo" icon={faBasketShopping} />
                        <svg id="numberArticleSVG" height="25" width="25">
                            <circle cx="12.5" cy="12.5" r="10" stroke="black" stroke-width="2" fill="white" />
                            <text id="textNumberArticleSVG" x="8" y="17">{nbArticle}</text>
                        </svg>
                    </button>
                </Navbar.Brand>
            )}
            */}
            <Navbar.Brand id="panier" as={link1} to={"/panier"}>
                <button className='navLink' id="buttonPanier">
                    <FontAwesomeIcon className={nbArticle === 1 ? ('text-danger') : ('text-secondary')} id="logo" icon={faBasketShopping} />
                    <svg id="numberArticleSVG" height="25" width="25">
                        <circle cx="12.5" cy="12.5" r="10" stroke="black" stroke-width="2" fill="white" />
                        <text id="textNumberArticleSVG" x="8" y="17">{nbArticle}</text>
                    </svg>
                </button>
            </Navbar.Brand>
            <Hamburger id="toggle" toggled={stateMenu} onToggle={() => { askMenuBurger() }} />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {location === '' ? (
                        <button className='navLink navLinkPadding' id="firstElt" onClick={() => { scrollToTopMainPage(); askMenuBurger() }}>Accueil</button>
                    ) : (
                        <Nav.Link className='navLink navLinkPadding' id="firstElt" onClick={() => { askMenuBurger() }} as={link1} to={"/"} >Accueil</Nav.Link>
                    )}
                    {location === '' ? (
                        <button className='navLink navLinkPadding' onClick={() => { scrollToAnchor(); askMenuBurger() }}>Votre Coach</button>
                    ) : (
                        <button className='navLink navLinkPadding' onClick={() => { goToHomeAndScroll(); askMenuBurger() }}>Votre Coach</button>
                    )}
                    <NavDropdown show={dropDown} onClick={dropDownFunction} className='navLink navLinkDropDownPadding' title="Coaching" id="basic-nav-dropdown">
                        <NavDropdown.Item as={link1} to={"/cel"} onClick={() => { askMenuBurger() }}>Coaching en Ligne</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={link1} to={"/cad"} onClick={() => { askMenuBurger() }}>Coaching à Domicile</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={link1} to={"/cev"} onClick={() => { askMenuBurger() }}>Coaching en Visio</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={link1} to={"/ces"} onClick={() => { askMenuBurger() }}>Coaching en Salle</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className='navLink navLinkPadding' as={link1} to={"/avis"} onClick={() => { askMenuBurger() }} >Avis</Nav.Link>
                    <Nav.Link className='navLink navLinkPadding' as={link1} to={"/contact"} onClick={() => { askMenuBurger() }} >Contact</Nav.Link>
                </Nav>

            </Navbar.Collapse>
        </Navbar >
    );
});

export default Navigation;