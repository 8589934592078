import React, { useEffect, useRef } from 'react';
import Card_cel from '../components/Card_cel';
import CEL_sec1 from '../components/CEL_sec1';
import Footer from '../components/Footer';

const Cel = ({ udapdeChildBasket }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const udapdeBasket = () => {
        //myRef.current?.navigateRefreshBasket();
        udapdeChildBasket()
    }

    return (
        <div>
            {/*<Navigation ref={myRef} />*/}
            <div id='offsetMenu' />
            <CEL_sec1 udapdeChildBasket={udapdeBasket} />
            <Card_cel />
            <Footer />
        </div>
    );
};

export default Cel;