import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import '../src/styles/ces_sec1.css'
import API from './Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { encode } from './EncodeDecrypt';
import OffreSelect from './OffreSelect';

const CES_sec1 = ({ udapdeChildBasket }) => {

    const [price, setPrice] = useState('')
    const [pricePromo, setPricePromo] = useState('')
    const [loading, setLoading] = useState(false)
    const unit = ["séance", "séances", "Solo", "Duo"]

    const updatePrice = (arrayPrice) => {
        if (arrayPrice != undefined) {
            setPrice(arrayPrice[0])
            if (arrayPrice.length === 2)
                setPricePromo(arrayPrice[1])
            else
                setPricePromo(null)
        }
    }

    const responseAPIStatusOk = (status) => {
        setLoading(status)
    }

    return (
        <div className='container'>
            <Row>
                <h1 className='fw-bold' id="title" >Coaching en Salle</h1>
                <h3 className='fw-bold text-center priceText'>{loading ? (
                    <section >
                        {pricePromo != null ? (
                            <section>
                                <span id='pricePromo'>{pricePromo}€  </span>
                                <span id='oldPrice'>{price}€</span>
                            </section>
                        ) : (
                            <span className=''>{price}€</span>
                        )}
                    </section>
                ) : (null)} </h3>
            </Row>
            <Row id="tarifTitle">
                <Col id="text" sm={4}>
                    <Row>
                        <OffreSelect productId={4} updatePrice={updatePrice} udapdeChildBasket={udapdeChildBasket} responseAPIStatusOk={responseAPIStatusOk} unit={unit} />
                    </Row>
                </Col>
                <Col>
                    <h1 className='fw-bold text-center' id="tarifLegend"><FontAwesomeIcon className='text-danger logoPin' icon={faLocationDot} />Basic FIT Les Sables d'Olonne </h1>
                    <h1 className='fw-bold text-center'>Comment le coaching en Salle se déroule-t-il ?</h1>
                </Col>
            </Row>
            <Row id="textSousTarif">
                <Col>
                    <p className='justifyText'>
                        Un entretien vous sera dédié afin que je puisse vous expliquer mon coaching, la manière dont je fonctionne et quelles sont les offres adaptées à votre envie et votre objectif.<br /><br />

                        Une fiche de renseignements vous sera transmise en main propre afin que je puisse mieux vous connaître et cerner au mieux vos attentes et votre objectif. Au vu de vos réponses, je vous réserve une première séance offerte dans le programme (le bilan de forme) qui me permettra de connaître vos capacités cardio-vasculaire,  musculaires ainsi que votre  souplesse & équilibre.  Une fois la séance réalisée, la fiche bilan me permettra mieux vous connaitre.<br /><br />

                        Suite à vos réponses, je commencerai la création de votre séance ou de votre programme personnalisé qui sera réalisé en fonction de votre/vos objectif(s), de vos attentes, de vos blessures, de vos antécédents, de votre quotidien etc.<br /><br />

                        Chaque fin de séance, nous effectuerons un bilan afin que je puisse répondre à vos questions et que l'on discute de vos ressentis durant la séance et post séance.
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default CES_sec1;