import React, { useEffect } from 'react';
import '../src/styles/home.css'
import * as Scroll from 'react-scroll';

import Footer from '../components/Footer';
import HomeBrique2_VotreCoach from '../components/HomeBrique2_VotreCoach';
import HomeBrique3_Votreobjectif from '../components/HomeBrique3_Votreobjectif';
import HomeBrique1_imageTop from '../components/HomeBrique1_imageTop';
import Navigation from './../components/Navigation';

const Home = () => {
    const ElementTop = Scroll.Element;
    const Element = Scroll.Element;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {/*<Navigation />*/}
            <ElementTop name="topPage" />
            <HomeBrique1_imageTop />
            <Element name="anchor" id='votreCoach'>
                <HomeBrique2_VotreCoach />
            </Element>
            <HomeBrique3_Votreobjectif />
            <Footer />
        </div>
    );
};

export default Home;