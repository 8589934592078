import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import Moment from 'react-moment';

const Review = ({ reviews, loading }) => {
    console.log(reviews)
    return (
        <div>
            {loading ? (
                <section id='spinnerReviewContainer'>
                    <Spinner id='spinnerReview' animation="border" role="status" />
                    <h1>Chargement</h1>
                </section>
            ) : (
                reviews.length === 0 || reviews.length === null ? (
                    <h3 className='text-center' style={{ padding: "80px 0" }}>Aucun avis, dépêchez-vous de laisser le vôtre :D</h3>
                ) : (
                    reviews.map(review => (
                        < Card >
                            <Card.Header>{review.coment}</Card.Header>
                            <Card.Body>
                                <blockquote className="blockquote mb-0">
                                    <p>
                                        {review.name}
                                    </p>
                                    <footer className="blockquote-footer">
                                        Laissé le <cite title="Source Title"><Moment format="DD/MM/YYYY" date={review.date} /></cite> avec un : {review.ref}.
                                    </footer>
                                </blockquote>
                            </Card.Body>
                        </Card >
                    ))
                )
            )}
        </div >

    );
};

export default Review;