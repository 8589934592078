import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

const CardItemBasket = ({ data }) => {
    return (
        <section id="recapBasket">
            <Row>
                <Col>
                    <Row>
                        <Col><h6 className='p-0 m-0 fw-bold'>{data.plans.name}</h6></Col>
                    </Row>
                    <Row>
                        <Col><p className='p-0 m-0'>
                            {data.plans.ref === "CEL" ? (
                                data.optn ? ("Sans suivi ") : ("Avec suivi 6/7 jours ")
                            ) : (
                                data.optn ? ("Duo ") : ("Solo ")
                            )}
                            X {data.name}
                            {data.plans.ref === "CEL" ? (" mois") : (
                                data.name === 1 ? (" séance") : (" séances")
                            )}
                            {console.log(data[0])}
                        </p></Col>
                    </Row>
                </Col>
                <Col>
                    <h5 className='fw-bold text-center tarifBasket'>
                        <section >
                            {data.promo ? (
                                <section>
                                    <span className='pricePromoBasket pricePromoBasketRecap'>{data.pricePromo}€  </span>
                                    <span className='oldPriceBasket oldPriceBasketRecap'>{data.price}€</span>
                                </section>
                            ) : (
                                <span className=''>{data.price}€</span>
                            )}
                        </section>
                    </h5>
                </Col>
            </Row >
        </section >
    );
};

export default CardItemBasket;