import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import API from '../components/Api'
import { toast } from 'react-toastify';
import '../src/styles/leaveAReview.css'

const LeaveAReview = () => {
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [message, setMessage] = useState("");

    const handleSendFormReview = async (e) => {
        e.preventDefault();
        try {
            const date = new Date();
            const response = await API.post("/feedback",
                {
                    name: name,
                    code: code,
                    coment: message,
                    date: date

                })
            if (response.status === 200) {
                console.log(response)
                switch (response.data) {
                    case 0:
                        toast.success("Avis laissé :)", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log("Avis laissé...")
                        break;
                    case 1:
                        toast.error("Avis déjà laisé ;)", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log("Avis déjà laisé")
                        break;
                    default:
                        toast.error("Vérifiez votre code d'avis ;)", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log("Vérifiez votre code d'avis")
                        break;
                }
            } else {
                toast.error("Problème avec l'avis laissé :(", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                console.log("Non laissé...")
            }
        } catch (err) {
            toast.error("Problème avec l'avis laissé :(", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            console.log(err)
        }
    }

    return (
        <div>
            <Form id="formContact">
                <Row>
                    <Col sm={12}>
                        <Form.Group className="mb-3">
                            <Form.Control type="email" value={name} placeholder="Entrez votre nom..." onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12}>
                        <Form.Group className="mb-3">
                            <Form.Control as="textarea" value={message} rows={10} id='textMessage' placeholder="Entrez votre avis..." onChange={(e) => setMessage(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Col sm={6}>
                            <Form.Group className="mb-3">
                                <Form.Control type="email" value={code} placeholder="Entrez le code recus par mail..." onChange={(e) => setCode(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Col>
                    <Col sm={6}>
                        <Button variant="danger" id="sendFormLeaveAReview" onClick={handleSendFormReview}>Ajouter</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default LeaveAReview;