import React from 'react';

const MentionsLegales = () => {
    return (
        <div>
            <div id='offsetMenu' />
            <div className='container'>
                <h1>Mentions légales :</h1>
                <ul>
                    <li>EI : Coaching-Val </li>
                    <li>Prénom - Nom : Valentin FAVRELIERE</li>
                    <li>Adresse : 19 Rue du Quartz 85340 Olonne sur mer </li>
                    <li>Mail : valentinfav@icloud.com</li>
                    <li>Téléphone : 06.28.75.02.06</li>
                    <li>N° Siret : 841 190 614 00021</li>
                    <li>Hébergeur : https://www.hostinger.fr</li>
                    <li>Adresse hébergeur : Hostinger International Ltd. 61 rue Lordou Vironos, 6023</li>
                    <li>Contact : Pas de support téléphonique de leur part chat uniquement</li>
                </ul>
            </div>
        </div>
    );
};

export default MentionsLegales;