import React, { useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route, MemoryRouter as RouterMemory } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import Home from './pages/Home';
import Cel from './pages/Cel';
import Cad from './pages/Cad';
import Cev from './pages/Cev';
import Ces from './pages/Ces';
import Avis from './pages/Avis';
import Contact from './pages/Contact';
import Panier from './pages/Panier';
import Navigation from './components/Navigation';
import MentionsLegales from './pages/MentionsLegales';

const App = () => {

  const myRef = useRef();

  const udapdeBasket = () => {
    console.log("update basket app")
    myRef.current?.navigateRefreshBasket();
  }

  const resetBasketAfterTouchLocalStorage = () => {
    console.log("reset basket after touch localStorage")
    myRef.current?.resetBasketAfterTouchLocalStorage();
  }

  const resetBasketNavPanier = () => {
    console.log("reset app")
    myRef.current?.resetBasketApp();
  }

  if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
  }

  return (
    <RouterMemory>
      <Navigation ref={myRef} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Home />} />
        <Route path="/cel" element={<Cel udapdeChildBasket={udapdeBasket} />} />
        <Route path="/cad" element={<Cad udapdeChildBasket={udapdeBasket} />} />
        <Route path="/cev" element={<Cev udapdeChildBasket={udapdeBasket} />} />
        <Route path="/ces" element={<Ces udapdeChildBasket={udapdeBasket} />} />
        <Route path="/avis" element={<Avis />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/panier" element={<Panier resetBasketNavPanier={resetBasketNavPanier} resetBasketAfterTouchLocalStorage={resetBasketAfterTouchLocalStorage} />} />
        <Route path="/mentionsLegales" element={<MentionsLegales />} />
      </Routes>
      <ToastContainer />
    </RouterMemory>
  );
};

export default App;

/* memory crt 
openssl pkcs12 -export -out cert.p12 -in cert.pem -inkey privkey.pem
*/