import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import API from '../Api'

const AppleGooglePay = ({ data, razBasket }) => {
    const stripe = useStripe()
    const elements = useElements()
    const [paymentRequest, setPaymentRequest] = useState(null)

    const sentenceRecap = () => {
        let offre = "Un " + data.plans.name + " pour une durée ";
        if (data.plans.ref === "CEL") {
            if (data.name === 1)
                offre = offre + "d'un mois ";
            else
                offre = offre + "de " + data.name + " mois ";
            if (data.optn)
                offre = offre + "avec suivi 6/7 jours.";
            else
                offre = offre + "sans suivi.";
        } else {
            if (data.name == 1)
                offre = offre + "d'une séance ";
            else
                offre = offre + "de " + data.name + " séances ";
            if (data.optn)
                offre = offre + "en duo.";
            else
                offre = offre + "solo.";
        }
        return offre;
    }

    useEffect(() => {
        console.log(data)
        let title = sentenceRecap()
        let price = data.promo ? (data.pricePromo) : (data.price)
        console.log(title)
        console.log("price " + price)
        if (!stripe || !elements) {
            return
        }

        const pr = stripe.paymentRequest({
            currency: 'eur',
            country: 'FR',
            requestPayerEmail: true,
            requestPayerName: true,
            requestPayerPhone: true,
            total: {
                label: title,
                amount: price * 100,
            }
        })
        pr.canMakePayment().then((result) => {
            if (result)
                setPaymentRequest(pr)
        })

        pr.on('paymentmethod', async (e) => {
            console.log(e.paymentMethod.id)
            console.log(e.paymentMethod.billing_details.email)
            console.log(e.paymentMethod.billing_details.name)
            console.log(e.paymentMethod.billing_details.phone)
            const response = await API.post(
                '/payement',
                {
                    id: e.paymentMethod.id,
                    uuid: data.id,
                    email: e.paymentMethod.billing_details.email,
                    phoneNumber: e.paymentMethod.billing_details.phone,
                    name: e.paymentMethod.billing_details.name
                });
            console.log("response back : " + response)
            if (response.status === 200 && response.data === "ok") {
                e.complete('success')
                razBasket()
            }
            else {
                e.complete('fail')
                return
            }
        })
    }, [stripe, elements])

    return (
        <section>
            {paymentRequest ? (
                console.log("ok apple"),
                <section id="buttonPaySection">
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                </section>
            ) : (
                null
            )}
        </section >
    );
};

export default AppleGooglePay;