import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../src/styles/cardDetailsCel.css'

const Card_cel = () => {
    return (
        <div className='container'>
            <Row xs={1} sm={4}>
                <Col className='d-flex'>
                    <Card id="cadreOneCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title'>Étape n°1</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle'>
                                    Contact / Appel
                                </h4>
                            </Card.Subtitle>
                            <Card.Title>
                                <ul>
                                    <li><h5>Fiche de renseignements</h5></li>
                                    <li><h5>Questionnaire alimentaire</h5></li>
                                    <li><h5>Bilan de forme physique</h5></li>
                                    <li><h5>Elaboration de votre objectif</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreTwoCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title text-white'>Étape n°2</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle text-white'>
                                    Votre programme personnalisé en fonction de :
                                </h4>
                            </Card.Subtitle>
                            <Card.Title className='text-white'>
                                <ul>
                                    <li><h5>Votre objectif défini</h5>  </li>
                                    <li><h5>Vos résultats du bilan de forme</h5></li>
                                    <li><h5>Vos antécédents médicaux et sportifs</h5></li>
                                    <li><h5>Votre quotidien</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreThreeCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title text-white'>Étape n°3</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle text-white'>
                                    Bilan / Suivi
                                </h4>
                            </Card.Subtitle>
                            <Card.Title className='text-white'>
                                <ul>
                                    <li><h5>Acompagnement 6j/7j</h5></li>
                                    <li><h5>Réponse à vos questions sous 24h</h5></li>
                                    <li><h5>Adaptation du programme en temps réel</h5></li>
                                    <li><h5>Bilan fin de semaine (Entrainement, fatigue, ...)</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreFourCEL" className='cellule'>
                        <Card.Body>
                            <Card.Title className='text-center'><span className=''> <span className='title'>Étape n°4</span></span></Card.Title>
                            <Card.Subtitle className='text-center'>
                                <h4 className='subtitle'>
                                    Conseils Nutritionnels
                                </h4>
                            </Card.Subtitle>
                            <Card.Title>
                                <ul>
                                    <li><h5>Adapté à votre objectif</h5></li>
                                    <li><h5>Adapté à votre quotidien</h5></li>
                                    <li><h5>Meilleure alimentation</h5></li>
                                    <li><h5>Rééquilibrage alimentaire</h5></li>
                                    <li><h5>Photos</h5></li>
                                </ul>
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Card_cel;