import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faSackDollar } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../src/styles/cardDetailsCad.css'

function GridExample() {
    return (
        <div className='container'>
            <Row xs={1} sm={3}>
                <Col className='d-flex'>
                    <Card id="cadreOne" className='cellule'>
                        <Card.Body>
                            <Card.Title> <span className='contentDetails'><FontAwesomeIcon className='text-danger' icon={faHourglass} /> <span className='textDetails text-white'>Gain de temps</span></span></Card.Title>
                            <Card.Text>
                                <h5 className='descriptionDetails text-white'>
                                    Fini le temps de trajet jusqu'à la salle de sport, le regard des autres, le temps d'attente pour les
                                    appareils, la garde des enfants.<br />
                                    Je vous propose des séances de sport personnalisées à domicile. Je m'adapte à votre emploi du temps grâce
                                    à des  horaires flexibles. Vous allez enfin pouvoir reprendre le sport !
                                </h5>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreTwo" className='cellule'>
                        <Card.Body>
                            <Card.Title> <span className='contentDetails'><FontAwesomeIcon className='text-danger' icon={faChartLine} /> <span className='textDetails text-black'>Progresion</span></span></Card.Title>
                            <Card.Text>
                                <h5 className='descriptionDetails text-black'>
                                    Le principal avantage est de faire appel à un coach sportif c'est de bénificier d'un suivi personnalisé et efficace.<br />
                                    Fini les séances lambda sans évolution !<br />
                                    Vous allez hériter d'un accompagnement sur mesure pour atteindre votre objectif.  Les séances de coaching seront donc adaptées à votre profil !<br /><br />

                                    Me faut-il du matériel ?<br />
                                    Non ! Votre coach s'occupe de tout.
                                </h5>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='d-flex'>
                    <Card id="cadreThree" className='cellule'>
                        <Card.Body>
                            <Card.Title><span className='contentDetails'><FontAwesomeIcon className='text-light' icon={faSackDollar} /> <span className='textDetails text-white'>Avantage fiscal</span></span></Card.Title>
                            <Card.Text>
                                <h5 className='descriptionDetails text-white'>
                                    Le coaching à domicile étant considéré comme un service à la personne, il donne droit à un crédit d'impôt.<br />
                                    Vous pourrez ainsi bénificier d'un avantage fiscal à la hauteur de 50% du montant de votre coaching.<br />
                                    Faire du sport, être en bonne santé et faire baisser ses impôts, en voila une excellente nouvelle !
                                </h5>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default GridExample;