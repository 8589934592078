import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { encode } from '../components/EncodeDecrypt';
import API from '../components/Api'

const OffreSelect = ({ productId, updatePrice, udapdeChildBasket, responseAPIStatusOk, unit }) => {

    let [options, setOptions] = useState('[]')
    const [loading, setLoading] = useState(true)
    const [OPTN1_dataAPI, setOPTN1_dataAPI] = useState('[]')
    const [OPTN2_dataAPI, setOPTN2_dataAPI] = useState('[]')

    useEffect(() => {
        window.scrollTo(0, 0)
        const getData = async () => {
            try {
                const data = await API.get(
                    "products/" + productId
                );
                let arrayOptnFalse = []
                let arrayOptnTrue = []
                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].optn == false) {
                        arrayOptnFalse.push(data.data[i])
                    } else {
                        arrayOptnTrue.push(data.data[i])
                    }
                }
                //send price to child
                if (arrayOptnFalse.length > 0) {
                    setOptions(arrayOptnFalse)
                    if (arrayOptnFalse[0].promo)
                        updatePrice([arrayOptnFalse[0].price, arrayOptnFalse[0].pricePromo])
                    else
                        updatePrice([arrayOptnFalse[0].price])
                } else {
                    setOptions(arrayOptnTrue)
                    if (arrayOptnTrue[0].promo)
                        updatePrice([arrayOptnTrue[0].price, arrayOptnTrue[0].pricePromo])
                    else
                        updatePrice([arrayOptnTrue[0].price])
                }
                setOPTN1_dataAPI(arrayOptnFalse)
                setOPTN2_dataAPI(arrayOptnTrue)

                console.log(data.data)
                if (OPTN1_dataAPI.length > 0 || OPTN2_dataAPI.length > 0) {
                    setLoading(false)
                    responseAPIStatusOk(true)
                }
            } catch (e) {
                setLoading(true)
                responseAPIStatusOk(false)
                console.log("error : " + e)
            }
        };
        getData();
    }, [])

    const handlePrice = event => {
        const selectNb = document.querySelector('#nbSeance').value
        try {
            //send price to child
            if (options[selectNb].promo)
                updatePrice([options[selectNb].price, options[selectNb].pricePromo])
            else
                updatePrice([options[selectNb].price])
        } catch {
            //send price to child
            if (options[0].promo)
                updatePrice([options[0].price, options[0].pricePromo])
            else
                updatePrice([options[0].price])
        }
    }

    const handleOptn = event => {
        document.querySelector('#nbSeance').selectedIndex = 0
        const selectNb = document.querySelector('#nbSeance').value
        const optionSelected = document.querySelector('#nbPerson').value
        console.log(selectNb)
        let arrayOptn = []
        if (JSON.parse(optionSelected)) {
            setOptions(OPTN2_dataAPI)
            arrayOptn = OPTN2_dataAPI
        } else {
            setOptions(OPTN1_dataAPI)
            arrayOptn = OPTN1_dataAPI
        }
        console.log(JSON.parse(optionSelected))
        console.log(options)
        console.log(arrayOptn)
        //send price to child
        try {
            if (arrayOptn[selectNb].promo)
                updatePrice([arrayOptn[selectNb].price, arrayOptn[selectNb].pricePromo])
            else
                updatePrice([arrayOptn[selectNb].price])
        } catch (e) {
            console.log(e)
            updatePrice(null)
        }
        //setOptions(arrayOptn)
    }

    const buy = event => {
        const nbSeance = document.querySelector('#nbSeance').value
        console.log(options[nbSeance])
        if (localStorage.getItem("basket") != null) {
            toast.error("Un seul article peut se glisser dans le pannier !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } else {
            toast.success("Article ajouter au panier !", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            const encrypt = encode(JSON.stringify(options[nbSeance]))
            localStorage.setItem("basket", encrypt)
            udapdeChildBasket()
        }
        console.log(localStorage.getItem("basket"))
    }
    return (
        <Form>
            <Form.Select disabled={!loading ? false : true} id="nbSeance" onChange={handlePrice} aria-label="Default select example">
                {!loading ? (
                    Object.values(options).map((n, index) => (
                        <option id="selected" key={index} value={index}>{n.name === 1 ? (n.name + " " + unit[0]) : (n.name + " " + unit[1])}</option>
                    ))
                ) : (<option key='0'>0</option>)}
            </Form.Select>
            <Form.Select disabled={!loading ? false : true} id="nbPerson" onChange={handleOptn} aria-label="Default select example" className='formMiddle'>
                {OPTN1_dataAPI.length > 0 ? (
                    <option value="false" >{unit[2]}</option>
                ) : (null)}
                {OPTN2_dataAPI.length > 0 ? (
                    <option value="true">{unit[3]}</option>
                ) : (null)}
            </Form.Select>
            <Button disabled={!loading ? false : true} onClick={buy} variant="danger" id="sub">AJOUTER AU PANIER</Button>
        </Form>
    );
};

export default OffreSelect;