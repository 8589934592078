import React, { useEffect, useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { Link as link1 } from "react-router-dom"
import { decode } from '../components/EncodeDecrypt';
import Footer from '../components/Footer';
import StripeContainer from '../components/stripe/StripeContainer';
import '../src/styles/basket.css'
import Navigation from './../components/Navigation';

const Panier = ({ resetBasketNavPanier, resetBasketAfterTouchLocalStorage }) => {

    const [ref, setRef] = useState('')
    const [errorBasket, setErrorBasket] = useState(false)

    useEffect(() => {
        let ref = null
        try {
            ref = decode(localStorage.getItem("basket"))
            if (ref === undefined)
                setErrorBasket(true)
            else
                setRef(ref)
        } catch (e) {
            setErrorBasket(true)
            resetBasketAfterTouchLocalStorage()
            console.log(e)
        }
    }, [])

    const resetBasketNavStripeContainer = () => {
        resetBasketNavPanier()
        console.log("reset stripe container")
    }

    return (
        <div>
            {/*<Navigation />*/}
            <div id='offsetMenu' />
            {!errorBasket ? (
                <section>
                    <StripeContainer resetBasketNavStripeContainer={resetBasketNavStripeContainer} />
                </section>
            ) : (
                <div id="empty">
                    <ul>
                        <li className='liStyle'><h2 className='textPanierVide'>Panier vide :'(</h2></li>
                        <li className='liStyle'><h2 className='textPanierVide'>Effectuez un achat :)</h2></li>
                    </ul>
                </div>
            )
            }
            {/*<Nav.Link className='navLink' id="returnHome" as={link1} to={"/"} >
                <Button className='basketButton' id="returnHome" variant="secondary" size="lg">Retour à l'aceuil</Button>
        </Nav.Link>*/}
            <Footer />
        </div>
    );
};

export default Panier;