import React from 'react';
import Pagination2 from 'react-bootstrap/Pagination';

const Pagination = ({ currentPage, reviewPerPage, totalReviews, paginate, paginateNext, paginatePrev, firstLastPage }) => {
    const pageNumber = []
    const nbPage = Math.ceil(totalReviews / reviewPerPage)

    for (let i = 1; i <= nbPage; i++) {
        pageNumber.push(i)
    }

    return (
        <div id="pagination">
            <Pagination2>
                <Pagination2.First onClick={() => firstLastPage(1)} />
                <Pagination2.Prev onClick={() => paginatePrev(currentPage)} />
                <ul className='pagination'>
                    {pageNumber.map(number => (
                        <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`} >
                            <a onClick={() => paginate(number)} className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
                <Pagination2.Next onClick={() => paginateNext(currentPage, nbPage)} />
                <Pagination2.Last onClick={() => firstLastPage(0, nbPage)} />
            </Pagination2>
        </div>
    );
};

export default Pagination;